<!--<template>-->
<!--    <v-container fluid>-->
<!--        <v-card>-->
<!--            &lt;!&ndash; toolbar 1 &ndash;&gt;-->
<!--            <v-toolbar-->
<!--                    color="secondary"-->
<!--                    flat-->
<!--                    dense-->
<!--            >-->
<!--                <v-toolbar-title>Inventory - Serial Numbers Report</v-toolbar-title>-->
<!--            </v-toolbar>-->
<!--            &lt;!&ndash; end &ndash;&gt;-->
<!--            &lt;!&ndash; toolbar 2 &ndash;&gt;-->
<!--            <v-toolbar-->
<!--                    color="secondary"-->
<!--                    flat-->
<!--                    dense-->
<!--            >-->
<!--                <v-spacer></v-spacer>-->
<!--                <v-text-field-->
<!--                        v-model="search"-->
<!--                        prepend-inner-icon="mdi-magnify"-->
<!--                        flat-->
<!--                        outlined-->
<!--                        solo-->
<!--                        dense-->
<!--                        label="search"-->
<!--                        style="width: 150px"-->
<!--                ></v-text-field>-->
<!--            </v-toolbar>-->
<!--            &lt;!&ndash; end &ndash;&gt;-->

<!--            <v-card-text>-->
<!--                <v-row dense>-->
<!--                    <v-col-->
<!--                            cols="12"-->
<!--                            sm="12"-->
<!--                            md="12"-->
<!--                    >-->
<!--                        <v-data-table-->
<!--                                dense-->
<!--                                v-if="docRights.read == true"-->
<!--                                :loading="loading"-->
<!--                                loading-text="Loading... Please wait"-->
<!--                                :headers="headers"-->
<!--                                :items="serialsReports"-->
<!--                        >-->
<!--                            &lt;!&ndash;            <v-data-table&ndash;&gt;-->
<!--                            &lt;!&ndash;              dense&ndash;&gt;-->
<!--                            &lt;!&ndash;              v-if="docRights.read == true"&ndash;&gt;-->
<!--                            &lt;!&ndash;              :loading="loading"&ndash;&gt;-->
<!--                            &lt;!&ndash;              loading-text="Loading... Please wait"&ndash;&gt;-->
<!--                            &lt;!&ndash;              :headers="headers"&ndash;&gt;-->
<!--                            &lt;!&ndash;              :items="masterData"&ndash;&gt;-->
<!--                            &lt;!&ndash;              :search="search"&ndash;&gt;-->
<!--                            &lt;!&ndash;            >&ndash;&gt;-->
<!--                            &lt;!&ndash;              <template v-slot:item.ItemName="{ item }">&ndash;&gt;-->
<!--                            &lt;!&ndash;                <v-btn&ndash;&gt;-->
<!--                            &lt;!&ndash;                  class="btnLink"&ndash;&gt;-->
<!--                            &lt;!&ndash;                  color="flatButton"&ndash;&gt;-->
<!--                            &lt;!&ndash;                  text&ndash;&gt;-->
<!--                            &lt;!&ndash;                  :to="`/item-master-data/${item.id}`"&ndash;&gt;-->
<!--                            &lt;!&ndash;                >{{ item.ItemName }}</v-btn>&ndash;&gt;-->
<!--                            &lt;!&ndash;              </template>&ndash;&gt;-->

<!--                            &lt;!&ndash;              <template v-slot:item.ItemCode="{ item }">&ndash;&gt;-->
<!--                            &lt;!&ndash;                <v-btn&ndash;&gt;-->
<!--                            &lt;!&ndash;                  class="btnLink"&ndash;&gt;-->
<!--                            &lt;!&ndash;                  color="flatButton"&ndash;&gt;-->
<!--                            &lt;!&ndash;                  text&ndash;&gt;-->
<!--                            &lt;!&ndash;                  :to="`/item-master-data/${item.id}`"&ndash;&gt;-->
<!--                            &lt;!&ndash;                >{{ item.ItemCode }}</v-btn>&ndash;&gt;-->
<!--                            &lt;!&ndash;              </template>&ndash;&gt;-->

<!--                            &lt;!&ndash;              <template v-slot:item.salesuom.UomName="{ item }">&ndash;&gt;-->
<!--                            &lt;!&ndash;                <span>{{item.salesuom ? item.salesuom.UomName : "-"}}</span>&ndash;&gt;-->
<!--                            &lt;!&ndash;              </template>&ndash;&gt;-->

<!--                            &lt;!&ndash;              <template v-slot:item.inventoryuom.UomName="{ item }">&ndash;&gt;-->
<!--                            &lt;!&ndash;                <span>{{item.inventoryuom? item.inventoryuom.UomName : "-"}}</span>&ndash;&gt;-->
<!--                            &lt;!&ndash;              </template>&ndash;&gt;-->

<!--                            &lt;!&ndash;              <template v-slot:item.purchaseuom.UomName="{ item }">&ndash;&gt;-->
<!--                            &lt;!&ndash;                <span>{{item.purchaseuom ? item.purchaseuom.UomName : "-"}}</span>&ndash;&gt;-->
<!--                            &lt;!&ndash;              </template>&ndash;&gt;-->
<!--                        </v-data-table>-->

<!--                        <v-alert-->
<!--                                type="error"-->
<!--                                v-if="docRights.read == false"-->
<!--                        >You are not authorised to read data</v-alert>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </v-card-text>-->
<!--        </v-card>-->
<!--        <snackbar ref="snackbar"></snackbar>-->
<!--    </v-container>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--    data: () => ({-->
<!--        search: "",-->
<!--        docRights: {},-->
<!--        serialsReports: [],-->
<!--        headers: [-->
<!--            { text: "Item Code", value: "ItemCode" },-->
<!--            { text: "Item Name", value: "ItemName" },-->
<!--            { text: "Serial Number", value: "DistNumber" },-->
<!--            { text: "System Number", value: "SysNumber" },-->
<!--            { text: "Warehouse", value: "WhsCode" },-->
<!--            { text: "Quantity", value: "Quantity" },-->
<!--            { text: "Commit Quantity", value: "CommitQty" },-->
<!--            { text: "Status", value: "Status" },-->
<!--            // { text: "Status", value: "Status" },-->
<!--            // { text: "On Order", value: "OnOrder" },-->
<!--        ],-->
<!--    }),-->
<!--    methods: {-->
<!--        getSerialNumbersReport() {-->
<!--            const self = this;-->
<!--            this.loading = true;-->
<!--            this.$store-->
<!--                .dispatch("get", `/inventory/serial-numbers/reports`)-->
<!--                .then((res) => {-->
<!--                    if (res.ResultCode == 1200) {-->
<!--                        self.serialsReports = res.ResponseData;-->
<!--                        self.loading = false;-->
<!--                    }-->
<!--                })-->
<!--                .catch((err) => {-->
<!--                    this.$refs.snackbar.show(err, "red");-->
<!--                });-->
<!--        },-->

<!--        customSearch() {-->
<!--            const self = this;-->
<!--            self.loading = true;-->
<!--            console.log("searching");-->
<!--            // this.$store-->
<!--            //     .dispatch("get", `/inventory/serial-numbers/reports`)-->
<!--            //     .then((res) => {-->
<!--            //         if (res.ResultCode == 1200) {-->
<!--            //             self.serialsReports = res.ResponseData;-->
<!--            //             self.loading = false;-->
<!--            //         }-->
<!--            //     })-->
<!--            //     .catch((err) => {-->
<!--            //         this.$refs.snackbar.show(err, "red");-->
<!--            //     });-->
<!--        },-->
<!--        checkRights() {-->
<!--            const self = this;-->
<!--            this.$store-->
<!--                .dispatch("get", `/authorization/check-if-permitted/3`)-->
<!--                .then((res) => {-->
<!--                    self.docRights = res;-->
<!--                })-->
<!--                .catch((err) => {-->
<!--                    this.$refs.snackbar.show(err, "red");-->
<!--                });-->
<!--        },-->
<!--    },-->
<!--    created() {-->
<!--        this.getSerialNumbersReport();-->
<!--        this.checkRights();-->
<!--    },-->
<!--};-->
<!--</script>-->


<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Inventory - Serial Numbers Report</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>

        <v-col cols="2">
          <v-btn icon class="ml-10" right @click="getSerialNumbersReport">
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="search"
                append-icon="mdi-search"
                label="Search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="serialsReports"
                :items-per-page="options.itemsPerPageOptions"
                :search="search"
                :options.sync="options"
                :server-items-length="totalItems"
                class="elevation-1"
                :server-search="true"
                :footer-props="{
                itemsPerPageOptions: [15, 30, 45, { text: 'All', value: -1 }],
                showCurrentPage: true,
              }"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog v-model="errorDocDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="errorDocDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:
              </v-col>
              <v-col cols="12">
                <span>
                  {{ this.ErrorMessage }}
                </span>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn color="green" dark @click="errorDocDialog = false"
                  >Close</v-btn
                  >
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of modal-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    serialsReports: [],
    errorDocDialog: false,
    ErrorMessage: null,
    loading: false,
    headers: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Name", value: "ItemName" },
      { text: "Serial Number", value: "DistNumber" },
      { text: "System Number", value: "SysNumber" },
      { text: "Warehouse", value: "WhsCode" },
      { text: "Quantity", value: "Quantity" },
      { text: "Commit Quantity", value: "CommitQty" },
      { text: "Status", value: "Status" },
    ],
    options: {
      // rowsPerPageOptions: [10, 20, 30],
      // itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 15,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.getSerialNumbersReport();
      },
      deep: true,
    },
    search() {
      this.options.page = 1;
      if (this.search.length >= 3) {
        this.getSerialNumbersReport();
      }
    },
  },
  methods: {
    getSerialNumbersReport() {
      const self = this;
      this.loading = true;
      this.$store
          // .dispatch("get", `/inventory/serial-numbers/reports?page=${self.options.page}&per_page=${self.options.itemsPerPage}&search=${self.search}`)
          .dispatch("get", `/inventory/serial-numbers/reports?page=${self.options.page}&per_page=${self.options.itemsPerPage}`)
          .then((res) => {
            if (res.ResultCode == 1200) {
              self.serialsReports = res.ResponseData.data;
              self.options.page = res.ResponseData.current_page;
              self.options.itemsPerPage = res.ResponseData.per_page;
              self.totalItems = res.ResponseData.total;
              self.loading = false;

            }
          })
          .catch((err) => {
            this.$refs.snackbar.show(err, "red");
          });
    },
    // getDocData() {
    //   const self = this;
    //   this.loading = true;
    //   this.docData = [];
    //   this.$store
    //       .dispatch(
    //           "get",
    //           `/web-gpm-scan-logs?page=${this.options.page}&per_page=${this.options.itemsPerPage}&search=${this.search}`
    //       )
    //       .then((res) => {
    //         if (res.ResultCode == 1200) {
    //           self.docData = res.ResponseData.data;
    //           self.options.page = res.ResponseData.current_page;
    //           self.options.itemsPerPage = res.ResponseData.per_page;
    //           self.totalItems = res.ResponseData.total;
    //           self.loading = false;
    //         }
    //       })
    //       .catch((err) => {
    //         this.$refs.snackbar.show(err, "red");
    //       });
    // },
    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
  },
  created() {
    // this.getSerialNumbersReport();
  },
};
</script>